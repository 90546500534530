button {
	display: block;
	width: 100%;
	margin: 22px 0px;
	border: none;
	border-radius: 5px;
	background-color: #e2156a;
	color: white;
	font-family: 'Nunito Sans', sans-serif;
	font-size: 1.125rem;
	cursor: pointer;
	height: 50px;
	font-weight: bolder;
}

button:hover {
	/* background-color: #4285f4; */
	background-color: #ed5a97;
}

button:active {
	transform: translateY(1px); /* Move the button down slightly */
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
}

/* Disabled button styles */
button:disabled {
	opacity: 0.6; /* Adjust opacity to visually indicate the button is disabled */
	cursor: not-allowed; /* Change cursor style for disabled state */
	/* Any other styles you want to apply to disabled buttons */
	transform: none;
	box-shadow: none;
}
