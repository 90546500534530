.image-container {
	/* width: calc(50% - (20px / 2)); */

	/* max-height: 100%; */
	/* background-color: #e2156a; */
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-container::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #e2156a; /* Change this to your desired color */
	mix-blend-mode: multiply; /* Applies color blending */
	/* height: 100vh; */
	height: auto;
	max-height: 100%;
}

.image-container img {
	/* margin-top: 20px; */
	width: 100%;
	/* height: 100vh; */
	object-fit: cover;
}

/* Media Queries*/
@media screen and (max-width: 978px) {
	.image-container {
		/* width: auto; */
		/* height: auto; */
		display: none;
	}
}
