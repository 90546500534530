

/* Media Queries*/

@media screen and (max-width: 978px) {
	.logo {
		height: auto;
		width: auto;
		transform: translate(-50%, 0%);
	}
}
