.telInput {
	width: 100%;
	border: 1px solid #aaa;
	border-radius: 8px;
	margin: 8px 0;
	outline: none;
	padding: 12px;
	box-sizing: border-box;
	transition: 0.3s;
}

.telInput:focus {
	border-color: #e2156a;
	box-shadow: 0 0 2px 0 #e2156a;
	caret-color: #e2156a;
	/* height: 3rem; */
}

.telInputWithIcon [type='tel'] {
	padding-left: 88px;
}

.telInputWithIcon {
	position: relative;
}

.telInputWithIcon i {
	position: absolute;
	left: 0;
	top: 8px;
	padding: 13px 9px;
	color: #aaa;
	transition: 0.3s;
}

.telInputWithIcon .telInput:focus + i {
	color: #e2156a;
	/* line-height: 2rem; */
	/* filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%); */
}

.customDropdown {
	appearance: none; /* Removes default appearance */
	-webkit-appearance: none; /* For older versions of webkit browsers */
	-moz-appearance: none; /* For older versions of Firefox */
	cursor: not-allowed; /* Change cursor to indicate not clickable */
	opacity: 1;

	/* Optional styles */
	border: none; /* Removes the border */
	background-color: transparent; /* Make the background transparent */

	/* Add additional styling as needed */
	color: #e2156a;
	font-weight: 600;
	position: relative;
	top: 0.05rem;
}

.cvl {
	border-left: 1px solid grey;
	height: 15px;
	/* padding-left: 2px; */
	/* padding-right: 2px; */
	margin-top: -1.22rem;
	margin-left: 4.4rem;
}
