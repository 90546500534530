/*Don't forget to add Font Awesome CSS : "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"*/
/* @import url('https://cdn.jsdelivr.net/npm/@fontawesome/fontawesome-free@6.5.1/css/all.min.css'); */

.textInput,
.passwordInput {
	width: 100%;
	border: 1px solid #aaa;
	border-radius: 8px;
	margin: 8px 0;
	outline: none;
	padding: 12px;
	box-sizing: border-box;
	transition: 0.3s;
}

.textInput:focus,
.passwordInput:focus,
.selectInput:focus {
	border-color: #e2156a;
	box-shadow: 0 0 2px 0 #e2156a;
	caret-color: #e2156a;
	/* height: 3rem; */
}

.inputWithIcon [type='text'],
.passwordInput,
.selectInput {
	padding-left: 40px;
}

.inputWithIcon {
	position: relative;
}

.inputWithIcon i {
	position: absolute;
	left: 0;
	top: 8px;
	padding: 11px 12px;
	color: #aaa;
	transition: 0.3s;
}

.inputWithIcon .textInput:focus + i,
.passwordInput:focus + i,
.selectInput:focus + i {
	color: #e2156a;
	/* line-height: 2rem; */
	/* filter: invert(17%) sepia(56%) saturate(6502%) hue-rotate(326deg)
		brightness(92%) contrast(93%); */
}

.inputWithIcon.inputIconBg i {
	background-color: #aaa;
	color: #fff;
	padding: 9px 4px;
	border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg .textInput:focus + i {
	color: #fff;
	background-color: #e2156a;
}

.selectInput {
	width: 100%;
	border: 1px solid #aaa;
	border-radius: 8px;
	margin: 8px 0;
	outline: none;
	padding: 12px 38px;
	box-sizing: border-box;
	transition: 0.3s;
	background: none;
	/** for the dropdown indicator */
	appearance: none;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1em;
	color: #6c757d;
}

/* -------------------------------------------------------------------------------------- */

.container {
	width: 100%;
	/* padding: 2rem; */
	// font-family: 'Inter', sans-serif;
	font-family: 'Nunito Sans', sans-serif;
}

.formContainer {
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
	/* max-width: 700px; */
	height: auto;
	overflow: hidden;
	/* border-radius: 10px; */
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(10px);
	/* margin: 40px auto; */
	width: 100%;
}

.form {
	font-family: 'Nunito Sans', sans-serif;
	// font-family: 'Inter', sans-serif;
	/* padding: 20px; */
	// width: 50%;
	/* padding-left: 40px; */
	/* padding-right: 20px; */
}

.form .term {
	// font-family: 'Inter', sans-serif;
	font-family: 'Nunito Sans', sans-serif;
	line-height: 16px;
	color: #1f2937;
	font-size: 14px;
	font-weight: 500;
}

.subhead {
	// font-family: 'Inter', sans-serif;
	font-family: 'Nunito Sans', sans-serif;
	padding-bottom: 8px;
	color: #1f2937;
	font-size: 14px;
	font-weight: 500;
}

.subhave {
	// font-family: 'Inter', sans-serif;
	font-family: 'Nunito Sans', sans-serif;
	padding-bottom: 8px;
	color: #1f2937;
	font-size: 14px;
	font-weight: 500;
}

.fullname {
	display: flex;
	justify-content: center;
	align-items: center;
}

.togglePassword {
	position: absolute;
	top: 50%;
	right: 12px; /* Adjust the position as needed */
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	background-size: cover;
	cursor: pointer;
	color: #b7bbc1;
}

.accountCreated {
	display: inline-block;
	border-radius: 2rem;
	box-shadow: 0 0 10px #7ce95d;
	padding: 0.5em 0.6em;
	color: #2c900f;
	/* color: white; */
	/* background-color: #4c996c; */
}

.innerform {
	/* padding-left: 6rem; */
	/* padding-right: 6rem; */
	padding-left: 10rem;
	padding-right: 5rem;
	padding-top: 2rem;
}

.businessInnerform {
	padding-left: 5rem;
	padding-right: 5rem;
	padding-top: 2rem;
}

.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif')
		50% 50% no-repeat rgb(249, 249, 249);
}

/* Media Queries*/
@media screen and (max-width: 978px) {
	.formContainer {
		flex-direction: column;
	}
	.form {
		width: 70%;
	}
	.form button {
		padding-left: 20px;
	}
	.innerform {
		padding-left: 0px;
		padding-right: 0px;
	}
	.businessInnerform {
		padding-left: 0rem;
		padding-right: 0rem;
		padding-top: 0rem;
	}
	.accountCreated {
		margin-top: 2rem;
	}
}

/* ------------------------------------------------------------------------------ */

.businessLogo {
	height: 4vh;
	padding: 12px;
	/* width: 10%; */
}

.tabContainer {
	display: flex;
	margin-bottom: 20px;
}

.tab {
	position: relative;
	padding: 6px 2px;
	margin-right: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.tab span {
	display: inline-block;
	font-size: 12px;
	padding: 2px 6px;
	white-space: nowrap;
}

.tab.active {
	color: #e2156a;
	font-weight: bold;
}
.tab.done {
	color: #059669;
	font-weight: bold;
	pointer-events: none;
}
.tab.deactive {
	color: #aaa;
	pointer-events: none;
}

.tab.active span::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 2px;
	background-color: #007bff;
}
.tab i {
	margin-right: 10px; /* Adjust the margin as needed */
	position: absolute;
	left: 0;
	top: 8px;
	padding: 9px 8px;
	color: #aaa;
	transition: 0.3s;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
	.tabContainer {
		flex-wrap: wrap; /* Allow tabs to wrap to the next line */
	}

	.tab {
		margin-bottom: 5px; /* Adjust spacing between tabs on smaller screens */
		margin-left: 10px; /*
    padding-left: 10px;
    }
    .tab i {
      margin-right: 10px; /* Adjust the margin as needed */
	}
}
