h1 {
    font-size: 2.5rem;
    font-weight: 800;
}

h2 {
    font-size: 1.5rem;
    font-weight: 800;
}

p {
    font-size: 1rem;
}

p a {
    text-decoration: none;
    color: #1E499F;
}

p a:hover {
    text-decoration: underline !important;
    color: #33569c;
}

h3 {
    font-size: 1em;
    color: purple;
    font-weight: bold;
}